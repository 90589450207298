<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-fecha">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Actualizar Fecha Vencimiento</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="">Fecha vencimiento</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_vencimiento"
                  :class="
                    $v.form.fecha_vencimiento.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @change="validarFecha()"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="hora_fin">Hora vencimiento</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="form.hora_vencimiento"
                  :class="
                    $v.form.hora_vencimiento.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  v-mask="{
                    mask: '99:99',
                    hourFormat: '24',
                  }"
                />
              </div>
            </div>
          </div>
          <div
            v-if="inspeccion.fecha_vencimiento < hoy"
            class="
                col-md-11
                pl-4
                d-flex
                align-items-stretch
                flex-column
              "
          >
            <div
              class="small-box alert-default-warning"
              style="min-height: 100px"
            >
              <div class="inner">
                <h5><strong>La inspección ya se encuentra vencida</strong></h5>
              </div>
              <div class="icon">
                <i class="far fa-calendar-plus"></i>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "HseInspeccionFechas",
  data() {
    return {
      inspeccion: {},
      hoy: moment().format("YYYY-MM-DD"),
      form: {
        fecha_vencimiento: null,
        hora_vencimiento: "23:59",
      },
    };
  },
  validations: {
    form: {
      fecha_vencimiento: {
        required,
      },

      hora_vencimiento: {
        required,
      },
    },
  },
  methods: {
    llenar_modal(inspeccion) {
      this.inspeccion = inspeccion;
      this.form.fecha_vencimiento = inspeccion.fecha_vencimiento;
      this.form.hora_vencimiento = inspeccion.hora_vencimiento;
      this.form.id = inspeccion.id;
      this.form.entidad = "tifinspecciones";
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: "PUT",
          url: "/api/tif/inspecciones",
          data: this.form,
        })
          .then((response) => {
            this.$refs.closeModal.click();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "Se realizo el cambio de estado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.form = {};
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    validarFecha() {
      // Se valida que la fecha inicial de la inspección sea menor que la fecha nueva
      if (this.form.fecha_vencimiento < this.hoy) {
        this.form.fecha_vencimiento = null;
        this.form.hora_vencimiento = null;
        this.$swal({
          icon: "error",
          title: `La fecha de la inspección no puede ser menor a la fecha actual...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
  },
};
</script>
